<template>
  <v-overlay :value="showOverlay" z-index="999">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  computed: {
    showOverlay() {
      return this.$store.state.layout.overlayLoader;
    },
  },
};
</script>
